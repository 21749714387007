import * as React from "react";
import { forwardRef } from "react";

function PaymentPrint() {
  return (
    <>
      <div className="printContainer">
        <div className="printDiv">
          <h6>Scheme name </h6>
          <h6>:</h6>
          <h6>1234</h6>
        </div>
        <div className="printDiv">
          <h6>Payment</h6>
          <h6>:</h6>
          <h6>1234</h6>
        </div>
        <div className="printDiv">
          <h6>Payment ID</h6>
          <h6>:</h6>
          <h6>1234</h6>
        </div>
        <div className="printDiv">
          <h6>Payment mode</h6>
          <h6>:</h6>
          <h6>Cash</h6>
        </div>
        <div className="printDiv">
          <h6>Date</h6>
          <h6>:</h6>
          <h6>Cash</h6>
        </div>
      </div>
    </>
  );
}
export default PaymentPrint;
