import { Button, Grid, FormControl, InputLabel } from "@mui/material";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import NavBarAdmin from "../components/NavBarAdmin";
import Sidebar from "../components/Sidebar";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactToPrint from "react-to-print";
import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect } from "react";
import PaymentPrint from "../components/PaymentPrint";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(231, 231, 242)",
    color: "rgb(47, 55, 70)",
    fontSize: 15,
    padding: "15px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "15px 10px",
    color: "rgb(17, 25, 39)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MemberPassbookReportUser = () => {
  const [schemeData, setSchemeData] = useState([]);

  const [memberHistory, setMemberHistory] = useState([]);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [selectedScheme, setSelectedScheme] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [reportType, setReportType] = useState("");
  const [months, setMonths] = useState("");
  const [toDate, setToDate] = useState(null);
  const [errorMsgGroup, setErrorMsgGroup] = useState("");
  const [errorMsgType, setErrorMsgType] = useState("");
  const [errorMsgToDate, setErrorMsgToDate] = useState("");
  const [errorMsgMonths, setErrorMsgMonths] = useState("");
  const [errorMsgFromDate, setErrorMsgFromDate] = useState("");

  

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  const user_id = localStorage.getItem("luserID");
  const fetchSchemeData = async () => {
    try {
      const response = await fetch(
        `https://localhost:44340/api/Admin/Getgrpsbyuser?id=${user_id}`
      );
      console.log(response);
      if (response.status === 200) {
        const schemes = await response.json();
        console.log(schemes);
        setSchemeData(schemes);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleFromDateChange = (newDate) => {
    const date = new Date(newDate);
    const formattedDate = format(date, "dd-MMM-yyyy");
    setFromDate(formattedDate);
    // setErrorMsgDate("");
    setErrorMsgFromDate("");
  };

  const handleToDateChange = (newDate) => {
    const date = new Date(newDate);
    const formattedDate = format(date, "dd-MMM-yyyy");
    setToDate(formattedDate);
    setErrorMsgToDate("");
    // setErrorMsgDate("");
  };

  const handleTypeActions = (event: SelectChangeEvent) => {
    setReportType(event.target.value);
    setErrorMsgType("");
    console.log(event.target.value);
    // onPaymentReport(event.target.value);
  };

  const handleMonthsActions = (event: SelectChangeEvent) => {
    setMonths(event.target.value);
    setErrorMsgMonths("");
    console.log(event.target.value);
    // onPaymentReport(event.target.value);
  };

  const onMemeberReport = async () => {
    setErrorMsgGroup(selectedScheme ? "" : "Select group");
    setErrorMsgFromDate(fromDate ? "" : "From date ");
    setErrorMsgToDate(toDate ? "" : "To date");
    setErrorMsgType(reportType ? "" : "Select type");
    setErrorMsgMonths(months ? "" : "Select month");

    if (selectedScheme && reportType) {
      if (reportType === "m" && months) {
        getdata();
      } else if (reportType === "d" && fromDate && toDate) {
        getdata();
      }
    }
  };
  const getdata = async () => {
    try {
      const url = new URL(
        "https://localhost:44340/api/Admin/Getmemppsbk_admin"
      );
      url.searchParams.append("group_id", selectedScheme);
      url.searchParams.append("user_id", user_id);
      url.searchParams.append("from_date", fromDate);
      url.searchParams.append("to_date", toDate);
      url.searchParams.append("type", reportType);
      url.searchParams.append("months", months);

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response);

      if (response.status === 200) {
        const data = await response.json();
        setMemberHistory(data);
        console.log(data);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const handleSchemeActions = (event: SelectChangeEvent) => {
    setSelectedScheme(event.target.value);
    setErrorMsgGroup("");
  };

  useEffect(() => {
    fetchSchemeData();
  }, []);

  return (
    <>
      <div
        className={`grid-container ${
          openSidebarToggle ? "grid-menu-hide" : ""
        }`}
      >
        <NavBarAdmin OpenSidebar={OpenSidebar} />
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <main className="main-container dashboardLarge">
          <div className="main-title ">
            <h3>Member passbook report</h3>
          </div>
          <div className="boxContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="group">Select group</InputLabel>
                  <Select
                    fullWidth
                    labelId="schemeData"
                    id="schemeData"
                    label="Select group"
                    value={selectedScheme}
                    onChange={handleSchemeActions}
                  >
                    {schemeData.map((scheme) => (
                      <MenuItem key={scheme.group_id} value={scheme.group_id}>
                        {scheme.group_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorMsgGroup && <p className="error">{errorMsgGroup}</p>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="reportType">Type</InputLabel>
                  <Select
                    labelId="reportType"
                    id="reportType"
                    value={reportType}
                    label="Type"
                    onChange={handleTypeActions}
                  >
                    <MenuItem value={"m"}>Monthly wise</MenuItem>
                    <MenuItem value={"d"}>Date wise</MenuItem>
                  </Select>
                </FormControl>
                {errorMsgType && <p className="error">{errorMsgType}</p>}
              </Grid>
              {reportType === "m" ? (
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="months">Months</InputLabel>
                    <Select
                      labelId="months"
                      id="months"
                      value={months}
                      label="Months"
                      onChange={handleMonthsActions}
                    >
                      <MenuItem value={"3"}>3 months</MenuItem>
                      <MenuItem value={"6"}>6 months</MenuItem>
                      <MenuItem value={"9"}>9 months</MenuItem>
                      <MenuItem value={"12"}>12 months</MenuItem>
                    </Select>
                  </FormControl>
                  {errorMsgMonths && <p className="error">{errorMsgMonths}</p>}
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        id="fromDate"
                        required
                        label="From date *"
                        defaultValue=""
                        onChange={handleFromDateChange}
                        // error={!!errorMsgDate}
                        format="DD-MMM-YYYY"
                        // helperText={errorMsgDate}
                        value={fromDate}
                      />
                    </LocalizationProvider>
                    {errorMsgFromDate && (
                      <p className="error">{errorMsgFromDate}</p>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        id="toDate"
                        required
                        label="To date *"
                        defaultValue=""
                        format="DD-MMM-YYYY"
                        onChange={handleToDateChange}
                        // error={!!errorMsgDate}
                        // helperText={errorMsgDate}
                        value={toDate}
                      />
                    </LocalizationProvider>
                    {errorMsgToDate && (
                      <p className="error">{errorMsgToDate}</p>
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={12} className="btnCenter">
                <Button
                  variant="contained"
                  size="large"
                  className="reportBtn"
                  onClick={onMemeberReport}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className="tableContainer">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {/* <StyledTableCell>#</StyledTableCell> */}
                    <StyledTableCell>Member name</StyledTableCell>
                    <StyledTableCell>Payment ID</StyledTableCell>
                    <StyledTableCell>Group value</StyledTableCell>
                    <StyledTableCell>Inst #</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Mode</StyledTableCell>

                    <StyledTableCell>Net payable</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memberHistory.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No data available
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    <>
                      {memberHistory.map((report) => (
                        <StyledTableRow key={report.name}>
                          <StyledTableCell>{report.name}</StyledTableCell>
                          <StyledTableCell>{report.payment_id}</StyledTableCell>
                          <StyledTableCell>{report.grp_amt}</StyledTableCell>
                          <StyledTableCell>
                            {report.installment}
                          </StyledTableCell>
                          <StyledTableCell>
                            {report.generated_date}
                          </StyledTableCell>
                          <StyledTableCell>
                            {report.payment_mode}
                          </StyledTableCell>

                          <StyledTableCell>{report.amount}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div></div>
        </main>
      </div>
    </>
  );
};

export default MemberPassbookReportUser;
