import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Images from "../utilities/Images";
import { Box, Container, Divider, IconButton,Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Login from "../auth/Login";
import Registration from "../auth/Registration";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import { Link, useLocation } from "react-router-dom";
const drawerWidth = 240;


import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import React from "react";

function Home(props) {
 
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [loginOpen, setloginOpen] = React.useState(false);
  const [regOpen, setRegOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const location = useLocation();
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const handleLoginModal = () => {
   // console.log("handleLoginModal clicked");
    setloginOpen(true);
  };

  const handleClose = () => {
    setloginOpen(false);
    setRegOpen(false);
  };

  const handleRegistrationModal = () => {
    setRegOpen(true);
  };

  const navItems = [
    { text: "Home", href: "#home" },
    { text: "About us", href: "#about" },
    { text: "Our services", href: "#services" },
    { text: "Why choose us", href: "#why-choose" },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <img
          className="logoImgNavbar"
          src={Images.logoNavbar.logoImage}
          alt={Images.logoNavbar.alt}
        />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              component={Link} // Use Link component for navigation
              to={location.pathname + item.href} // Create the full path
            >
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <div className="heroContainer">
        
        <div className="navbarHome">
        <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="static"
          sx={{ background: "transparent", boxShadow: "none", paddingTop: 3 }}
        >
          <Container maxWidth="lg">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 1, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <img
                  className="logoImgNavbar"
                  src={Images.logoNavbar.logoImage}
                  alt={Images.logoNavbar.alt}
                />
              </Typography>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                {navItems.map((item) => (
                  <Button
                    key={item.text}
                    href={item.href}
                    style={{ color: "#fff" }}
                    className="menuList"
                  >
                    {item.text}
                  </Button>
                ))}
              </Box>
              <Button
                onClick={handleLoginModal}
                sx={{
                  fontSize: 18,
                  color: "#0e3b7f",
                  textTransform: "capitalize",
                }}
                className="signinBtn"
              >
                Signin
              </Button>
              <Button
                onClick={handleRegistrationModal}
                sx={{
                  fontSize: 18,
                  color: "#0e3b7f",
                  textTransform: "capitalize",
                }}
                className="signinBtn"
              >
                Signup
              </Button>
            </Toolbar>
          </Container>
        </AppBar>

        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>

     
        </div>
        <div className="sliderContainer" id="home">
          <Container maxWidth="lg">
          {/* <Carousel
              autoPlay={true}
              infiniteLoop={false}
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              interval={3000}
            >
              {Images.bannerImg.map((image, index) => (
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    className="sliderInfo"
                  >
                    <h2 className="bannertTitle">{image.title}</h2>
                    <p className="bannnerDesc">{image.desc}</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    className="bannerImgDiv"
                  >
                    <Box
                      component="img"
                      className="bannerImg animationImg"
                      src={image.url}
                      alt={image.title}
                    />
                  </Grid>
                </Grid>
              ))}
            </Carousel> */}

            <Carousel
              autoPlay={true}
              infiniteLoop={false}
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              interval={3000}
            >
              
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    className="sliderInfo"
                  >
                    <h2 className="bannertTitle">Chit Funds</h2>
                    <p className="bannnerDesc">Financial arrangement or savings scheme commonly found in India, although similar systems exist in other parts of the world under different names. It operates as a rotating savings and credit association, providing a means for individuals to pool their resources and save money collectively.</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    className="bannerImgDiv"
                  >
                    <Box
                      component="img"
                      className="bannerImg animationImg"
                      src={Images.bannerImg.url}
                      alt={Images.bannerImg.alt}
                    />
                  </Grid>
                  <p>
                <p></p>
              </p>
                </Grid>
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    className="sliderInfo"
                  >
                    <h2 className="bannertTitle">Chit 123</h2>
                    <p className="bannnerDesc">Financial arrangement or savings scheme commonly found in India, although similar systems exist in other parts of the world under different names. It operates as a rotating savings and credit association, providing a means for individuals to pool their resources and save money collectively.</p>
                    <Button
                 onClick={() => handleLoginModal()}
                sx={{
                  fontSize: 18,
                  color: "#0e3b7f",
                  textTransform: "capitalize",
                }}
                className="signinBtn"
              >
                Signin
              </Button>
              <p>
                <p></p>
              </p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    className="bannerImgDiv"
                  >
                    <Box
                      component="img"
                      className="bannerImg animationImg"
                      src={Images.bannerImg.url}
                      alt={Images.bannerImg.alt}
                    />
                  </Grid>
                </Grid>
              
            </Carousel>
          </Container>
        </div>
      </div>

      <div className="servicesSec secPadding" id="services">
        <Container maxWidth="lg">
          <div className=" ">
            <div className="secHead">
              <h2 className="secHeading">Our services</h2>
              <p className="textCenter captionSec ">
                There are many variations of chit schemes with different services provided on basis of requirment.
              </p>
            </div>
            <Grid container spacing={4}>
              {Images.servicesImg.map((service, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                  <div class="serviceBox ">
                    <div class="img-box">
                      <img src={service.url} alt="" width="70px" />
                    </div>
                    <div class="detail-box">
                      <h3>{service.title}</h3>
                      <p>{service.desc}</p>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </div>

      <div className="aboutSec secPadding" id="about">
        <Container maxWidth="lg">
          <div className="">
            <div className="secHead">
              <h2 className="secHeading">About us</h2>
              <p className="textCenter captionSec whiteColor ">
              Financial arrangements where a group of individuals pool money through periodic contributions.
              </p>
            </div>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <img
                  src={Images.about.img}
                  alt={Images.about.alt}
                  className="fullWidth"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                <p className="textJustify">
                Chit funds, prevalent in various cultures, represent a unique form of financial collaboration where a group of individuals voluntarily come together to contribute fixed amounts at regular intervals. Organized by a chit fund company or a trusted entity, the cumulative pool is then auctioned or allocated through a lottery system to one participant in each cycle. This continues until all members have received the lump sum, providing participants with access to a sizable amount of money at different intervals. While chit funds can serve as an informal savings and credit mechanism, offering financial flexibility, participants should exercise caution as the success of such arrangements relies on trust, transparency, and adherence to legal and regulatory frameworks to ensure the security and fair operation of the fund.
                </p>
              
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>

      <div className="servicesSec secPadding " id="why-choose">
        <Container maxWidth="lg">
          <div className="">
            <div className="secHead">
              {<h2 className="secHeading">Why choose us</h2>}
              <p className="textCenter captionSec ">
              Investment can be done in small amount. 
              Enables investors to access emergency fund. No paperwork required.
              </p>
            </div>
            <Grid container spacing={3}>
              {Images.servicesImg.map((service, index) => (
                <Grid item xs={12} key={index}>
                  <div class="serviceBox animationSec">
                    <div class="img-box">
                      <img src={service.url} alt="" width="80px" />
                    </div>
                    <div class="detail-box">
                      <h3>{service.title}</h3>
                      <p>{service.desc}</p>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </div>

      <div className="footerSec">
        <Container maxWidth="lg">
          <div className="footerContainer">
            <p>© 2023 Chit Fund - All Rights Reserved.</p>
            <ul>
              <li>
                <a href="#">
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a href="#">
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a href="#">
                  <YouTubeIcon />
                </a>
              </li>
              <li>
                <a href="#">
                  <TwitterIcon />
                </a>
              </li>
              <li>
                <a href="#">
                  <LinkedInIcon />
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={loginOpen}
        className="modalDialogPadding"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent xs={{ padding: 0 }}>
          <Login />
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={regOpen}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent xs={{ padding: 0 }}>
          <Registration />
        </DialogContent>
      </BootstrapDialog>
      
    </>
  );
}

Home.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Home;
