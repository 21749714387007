import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import NavBarAdmin from "../components/NavBarAdmin";
import Sidebar from "../components/Sidebar";
import { Grid } from "@mui/material";
import AlertDialog from "../utilities/AlertDialog";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(231, 231, 242)",
    color: "rgb(47, 55, 70)",
    fontSize: 15,
    padding: "15px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "15px 10px",
    color: "rgb(17, 25, 39)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(sno, groupName, group_id, amount, userName, phone, userId) {
  return { sno, groupName, group_id, amount, userName, phone, userId };
}

export default function ChitMain() {
  const [openSidebarToggle, setOpenSidebarToggle] = React.useState(false);
  const [memberData, setMemberData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState("");
  const snoCounter = React.useRef(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsgGroup, setErrorMsgGroup] = useState("");
  const [errorMsgUser, setErrorMsgUser] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [tableVisible, setTableVisible] = useState(false);
  const [savedGroup, setSavedGroup] = useState("");
  const [savedUser, setSavedUser] = useState("");
  const [modalOpen, setModalOpen] = React.useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const fetchMemberData = async () => {
    try {
      const response = await fetch(
        "https://localhost:44340/api/Admin/Getschemes"
      );

      if (response.status === 200) {
        const data = await response.json();
        const rows = data.map((record) =>
          createData(
            "",

            record.group_name,
            record.group_id,
            record.amount,
            "",
            0
          )
        );
        setMemberData(rows);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://localhost:44340/api/Admin/Getusers"
      );

      if (response.status === 200) {
        const data = await response.json();

        setUserData(data);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchMemberData();
    fetchUserData();
  }, []);

  const handleAddButton = () => {
    setErrorMsgGroup(selectedGroup ? null : "Please select valid group");
    setErrorMsgUser(selectedUser ? null : "Please select valid username");
    // if (!selectedGroup) {
    //   setErrorMsgGroup("Please select a valid group");
    //   return;
    // }

    if (selectedGroup && selectedUser) {
      const group = memberData.find(
        (group) => group.groupName === selectedGroup
      );

      const user = userData.find((user) => user.username === selectedUser);
      const newRow = createData(
        snoCounter.current++,
        selectedGroup,
        group.group_id,
        group.amount,
        selectedUser,
        user.phone,
        user.user_id
      );
      setData([...data, newRow]);
      setSavedGroup(selectedGroup);
      setSavedUser(selectedUser);
      setShowSaveButton(true);
      setTableVisible(true);
      // setSelectedGroup("");
      setSelectedUser("");
    }
  };

  const onResetBtn = () => {
    setSelectedGroup("");
    setSelectedUser("");
    setTableVisible(false);
    setErrorMsgGroup("");
    setErrorMsgUser("");
    setErrorMsg("");
    setData([]); // a
  };

  // Function to handle row deletion
  const handleRowDelete = (rowSno) => {
    const updatedData = data.filter((row) => row.sno !== rowSno);
    setData(updatedData);
  };

  const handleSaveButton = () => {
    if (data.length === 0) {
      // Handle the case where there are no rows in the table
      setErrorMsg("No data to save");
      return;
    }

    const savedGroupData = memberData.find(
      (group) => group.groupName === savedGroup
    );

    // Filter out the rows that were added to the table
    const savedUserData = data.map((row) => row.userId);

    //  Data to be sent to the API
    const requestData = {
      group_id: savedGroupData ? savedGroupData.group_id : null,
      user_id: savedUserData,
    };
    console.log("Request data:", requestData);
    fetch("https://localhost:44340/api/Admin/Save_mem_grp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          const message = (
            <div className="messageDiv">
              <p> Chit members added successfully</p>
            </div>
          );
          setSuccessMsg(message);

          handleModalOpen();
          setData([]);
          setShowSaveButton(false);
          onResetBtn();
        } else {
          setErrorMsg("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        setErrorMsg("Something went wrong: ");
      });

    // Reset the savedGroup and savedUser state variables
    setSavedGroup("");
    setSavedUser([]);
  };

  return (
    <>
      <div
        className={`grid-container ${
          openSidebarToggle ? "grid-menu-hide" : ""
        }`}
      >
        <NavBarAdmin OpenSidebar={OpenSidebar} />
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div className="main-container dashboardLarge">
          <div className="main-title">
            <h3>Chit</h3>
          </div>
          <div className="boxContainer">
            <div className="search-container">
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={5} lg={5} xl={4}>
                    <Autocomplete
                      id="group"
                      freeSolo
                      options={memberData.map((option) => option.groupName)}
                      value={selectedGroup}
                      onChange={(event, newValue) => {
                        setSelectedGroup(newValue);
                        setErrorMsgGroup("");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select group" />
                      )}
                    />
                    {errorMsgGroup && (
                      <div className="error">{errorMsgGroup}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={5} lg={5} xl={4}>
                    <Autocomplete
                      freeSolo
                      id="userID"
                      disableClearable
                      options={userData.map((option) => option.username)}
                      value={selectedUser}
                      onChange={(event, newValue) => {
                        setSelectedUser(newValue);
                        setErrorMsgUser("");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select user name"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                    {errorMsgUser && (
                      <div className="error">{errorMsgUser}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} lg={2} xl={4}>
                    <Button
                      variant="contained"
                      size="large"
                      className="reportBtn"
                      onClick={handleAddButton}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          {tableVisible && data.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {/* <StyledTableCell>#</StyledTableCell> */}
                      <StyledTableCell>Group name</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>User name</StyledTableCell>
                      <StyledTableCell>Phone</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <StyledTableRow key={row.sno}>
                        {/* <StyledTableCell component="th" scope="row">
                          {row.sno}
                        </StyledTableCell> */}
                        <StyledTableCell>{row.groupName}</StyledTableCell>
                        <StyledTableCell>{row.amount}</StyledTableCell>
                        <StyledTableCell>{row.userName}</StyledTableCell>
                        <StyledTableCell>{row.phone}</StyledTableCell>
                        <StyledTableCell>
                          <DeleteIcon
                            color="error"
                            onClick={() => handleRowDelete(row.sno)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="btnCenter mt-20">
                {showSaveButton && (
                  <Button
                    variant="contained"
                    className="save-button"
                    sx={{ mr: 2 }}
                    onClick={handleSaveButton}
                  >
                    Save
                  </Button>
                )}
                {showSaveButton && (
                  <Button
                    type="reset"
                    variant="contained"
                    color="secondary"
                    onClick={onResetBtn}
                  >
                    Reset
                  </Button>
                )}
              </div>
              {setErrorMsg && <p className="error errorBtn">{errorMsg}</p>}
            </>
          )}
        </div>
      </div>

      <AlertDialog
        title="Success"
        content={successMsg}
        actionName="Ok"
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
      />
    </>
  );
}
