import * as React from "react";
import { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import NavBarAdmin from "../components/NavBarAdmin";
import Sidebar from "../components/Sidebar";
import AlertDialog from "../utilities/AlertDialog";

const Profile = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgAddress, setErrorMsgAddress] = useState("");
  const [errorMsgPhone, setErrorMsgPhone] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://localhost:44340/api/Admin/Save_user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "A",
            username: name,
            emailid: email,
            phone: phone,
            address: address,
          }),
        }
      );

      if (response.status === 200) {
        const token = await response.json();
        console.log(token);
        handleModalOpen(true);
        const message = (
          <div className="messageDiv">
            <p>Your User ID is: {token}</p>

            <p className="successMsg"> New user added successfully</p>
          </div>
        );
        setSuccessMsg(message);

        onResetBtn();
      } else {
        setErrorMsg("Something went wrong");
      }

      // setuserid(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleActions = (e) => {
    const { id, value } = e.target;

    if (id === "name") {
      setName(value);
      setErrorMsgName("");
    } else if (id === "email") {
      setEmail(value);
      setErrorMsgEmail("");
    } else if (id === "phone") {
      setPhone(value);
      setErrorMsgPhone("");
    } else if (id === "address") {
      setAddress(value);
      setErrorMsgAddress("");
    } else {
      setErrorMsg("");
    }
  };
  const onResetBtn = () => {
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
  };
  const onNewUserButton = () => {
    setErrorMsgName(name ? null : "Please enter name");
    setErrorMsgEmail(email ? null : "Please enter email");
    setErrorMsgPhone(phone ? null : "Please enter phone number");
    setErrorMsgAddress(address ? null : "Please enter address");

    if (name && email && phone && address) {
      fetchData();
    }
    // setErrorMsg("Invalid details");
  };

  return (
    <>
      <div
        className={`grid-container ${
          openSidebarToggle ? "grid-menu-hide" : ""
        }`}
      >
        <NavBarAdmin OpenSidebar={OpenSidebar} />
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <main className="main-container dashboardLarge">
          <div className="main-title ">
            <h3>Member Details</h3>
          </div>

          <div className="boxContainer textCenter">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  id="name"
                  label="Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={name}
                  sx={{ marginBottom: 3 }}
                  onChange={handleActions}
                  error={!!errorMsgName}
                  helperText={errorMsgName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  id="phone"
                  label="Phone number"
                  type="number"
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onChange={handleActions}
                  error={!!errorMsgPhone}
                  helperText={errorMsgPhone}
                  inputProps={{ maxLength: 10 }}
                  value={phone}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  id="email"
                  label="Email ID"
                  type="text"
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onChange={handleActions}
                  error={!!errorMsgEmail}
                  helperText={errorMsgEmail}
                  value={email}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  id="address"
                  label="Address"
                  type="text"
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onChange={handleActions}
                  error={!!errorMsgAddress}
                  helperText={errorMsgAddress}
                  value={address}
                />
              </Grid>
            </Grid>
            <div className="btnCenter mt-10">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mr: 2 }}
                onClick={onNewUserButton}
              >
                Save
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="secondary"
                onClick={onResetBtn}
              >
                Reset
              </Button>
            </div>
            {setErrorMsg && <p className="error errorBtn">{errorMsg}</p>}
          </div>
        </main>
      </div>
      <AlertDialog
        title="Success"
        content={successMsg}
        action="toSubmit"
        actionName="Ok"
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
      />
    </>
  );
};
export default Profile;
