import React from "react";
import {
  BsFillBellFill,
  BsFillEnvelopeFill,
  BsPersonCircle,
  BsSearch,
  BsJustify,
} from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

function NavBarAdmin({ OpenSidebar }) {
  const [auth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userName, setuserName] = useState("");

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const tokenData = localStorage.getItem("luserName");
    setuserName(tokenData);
  }, []);

  return (
    <header className="header">
      <div className="menu-icon">
        <BsJustify className="icon" onClick={OpenSidebar} />
      </div>
      <div className="header-left">{/* <BsSearch  className='icon'/> */}</div>
      <div className="header-right">
        {auth && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <BiUser sx={{ color: "#6366f1", fontSize: 28 }} />
              {/* <span className='userName'>{userid}</span> */}
              <span className="userName">{userName}</span>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <PermIdentityIcon sx={{ mr: 2 }} />
                {userName}
              </MenuItem>
              {/* <MenuItem cnClick={() => navigate("/profile")}>
                <PermIdentityIcon sx={{ mr: 2 }} />
                Profile
              </MenuItem> */}
              <MenuItem onClick={() => navigate("/")}>
                <ExitToAppIcon sx={{ mr: 2 }} />
                Logout
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </header>
  );
}

export default NavBarAdmin;
