import { useState } from "react";
import Sidebar from "../components/Sidebar";
import "../assets/admin.css";
import NavBarAdmin from "../components/NavBarAdmin";
import Dashboard from "./Dashboard";

function AdminPage() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <div
      className={`grid-container ${openSidebarToggle ? "grid-menu-hide" : ""}`}
    >
      <NavBarAdmin OpenSidebar={OpenSidebar} />
      <Sidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <Dashboard />
    </div>
  );
}

export default AdminPage;
