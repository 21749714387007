import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import NavBarAdmin from "../components/NavBarAdmin";
import Sidebar from "../components/Sidebar";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
// import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import styled from "styled-components";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import PaymentPrint from "../components/PaymentPrint";
const StyledCell = styled.div`
  &.Pending {
    color: #ff9800;
  }

  &.Fulfilled {
    color: green;
  }
`;

function PaymentReport() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [schemeData, setSchemeData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);

  const [data, setData] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState([]);
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const fetchSchemeData = async () => {
    try {
      const response = await fetch(
        `https://localhost:44340/api/Admin/Getschemes`
      );

      if (response.status === 200) {
        const schemes = await response.json();
        setSchemeData(schemes);
        //console.log("Scheme Data:", schemes);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleSchemeActions = async (e) => {
    const id = e.target.value;
    setSelectedScheme(id);
    const url = id
      ? `https://localhost:44340/api/Admin/Getpayment?group_id=${id}`
      : "https://localhost:44340/api/Admin/Getpayment";
    // const url = `https://localhost:44340/api/Admin/Getpayment?group_id=${selectedScheme}`;

    try {
      const response = await fetch(url);
      if (response.status === 200) {
        const responseData = await response.json();
        const dataWithSno = responseData.map((item, index) => ({
          ...item,
          sno: index + 1,
        }));
        setData(dataWithSno); // Make sure responseData is an array of data objects
      } else {
        console.error("Error fetching data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function getCssClass(value) {
    if (value === "P") return "Pending";
    else if (value === "F") return "Fulfilled";
  }

  // Define your DataTable columns
  const columns = [
    {
      name: "S.No",
      selector: "sno",
      sortable: true,
      width: "80px",
    },
    {
      name: "Customer name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Phone number",
      selector: "phone",
      sortable: true,
      //cell: (d) => <span>{d.genres.join(", ")}</span>,
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
    },

    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <>
          <StyledCell className={getCssClass(row.status)}>
            <CircleIcon sx={{ fontSize: 16, marginRight: 1 }} />
          </StyledCell>

          <StyledCell className={getCssClass(row.status)}>
            {getCssClass(row.status)}
          </StyledCell>
        </>
      ),
    },
    // {
    //   name: "Action",
    //   sortable: false,
    //   selector: "null",
    //   cell: (d) => [
    //     <>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="small"
    //         sx={{ marginRight: 0 }}
    //       >
    //         <EditIcon />
    //       </Button>
    //       {/* <Button variant="contained" size="small" color="error">
    //         <DeleteOutlineIcon />
    //       </Button> */}
    //     </>,
    //   ],
    // },
  ];

  useEffect(() => {
    fetchSchemeData();
    // // Fetch data when the component mounts
  }, []);

  const tableData = {
    columns,
    data,
  };

  return (
    <div
      className={`grid-container ${openSidebarToggle ? "grid-menu-hide" : ""}`}
    >
      <NavBarAdmin OpenSidebar={OpenSidebar} />
      <Sidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <main className="main-container dashboardLarge">
        <div className="main-title ">
          <h3>Payment report</h3>
        </div>
        <Box className="boxContainer ">
          <div className="mb-20">
            <Grid container>
              <Grid item xs={12} sm={8} md={6} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="schemeData">Select group</InputLabel>
                  <Select
                    fullWidth
                    labelId="schemeData"
                    id="schemeData"
                    label="Select group"
                    value={selectedScheme}
                    onChange={handleSchemeActions}
                  >
                    {schemeData.map((scheme) => (
                      <MenuItem key={scheme.group_id} value={scheme.group_id}>
                        {scheme.group_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <DataTableExtensions
            {...tableData}
            print={false}
            export={false}
            filterPlaceholder="Search by any"
          >
            <DataTable
              columns={columns}
              data={data}
              defaultSortField="sno"
              //sortIcon={<SortIcon />}
              defaultSortAsc={true}
              pagination
              // noDataComponent={<div>No data available</div>}
              highlightOnHover
              className="paymentHistory"
              print={false}
              export={false}
            />
          </DataTableExtensions>
        </Box>
      </main>
    </div>
  );
}

export default PaymentReport;
