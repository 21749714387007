import * as React from "react";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Images from "../utilities/Images";
import AlertDialog from "../utilities/AlertDialog";

const Registration = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [conPassword, setconPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgPhone, setErrorMsgPhone] = useState("");
  const [errorMsgAddress, setErrorMsgAddress] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorMsgConPassword, setErrorMsgConPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://localhost:44340/api/Admin/Save_user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "M",
            username: name,
            emailid: email,
            password: password,
            phone: phone,
            address: address,
          }),
        }
      );

      if (response.status === 200) {
        const token = await response.json();
        console.log(token);
        handleModalOpen(true);
        const message = (
          <div className="messageDiv">
            <p>Your User ID is: {token}</p>

            <p className="successMsg"> Registration successfully</p>
          </div>
        );
        setSuccessMsg(message);
        onResetBtn();
        // navigate("/admin", { state: { username } });
      } else {
        setErrorMsg("Something went wrong");
      }

      // setuserid(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleActions = (e) => {
    const { id, value } = e.target;

    if (id === "name") {
      setName(value);
      setErrorMsgName("");
    } else if (id === "email") {
      setEmail(value);
      setErrorMsgEmail("");
    } else if (id === "phone") {
      setPhone(value);
      setErrorMsgPhone("");
    } else if (id === "address") {
      setAddress(value);
      setErrorMsgAddress("");
    } else if (id === "password") {
      setPassword(value);
      setErrorMsgPassword("");
    } else if (id === "conPassword") {
      setconPassword(value);
      setErrorMsgConPassword("");
    } else {
      setErrorMsg("");
    }
  };
  const onResetBtn = () => {
    setName("");
    setEmail("");
    setPassword("");
    setconPassword("");
  };
  const onRegistrationButton = () => {
    setErrorMsgName(name ? null : "Please enter name");
    setErrorMsgEmail(email ? null : "Please enter email");
    setErrorMsgPhone(phone ? null : "Please enter phone");
    setErrorMsgAddress(address ? null : "Please enter address");
    if (password !== conPassword) {
      setErrorMsgPassword("Passwords do not match");
      setErrorMsgConPassword("Passwords do not match");
    } else if (password === "" && conPassword === "") {
      setErrorMsgPassword("");
      setErrorMsgConPassword("");
    } else {
      setErrorMsgPassword("");
      setErrorMsgConPassword("");
    }

    if (!password) {
      setErrorMsgPassword("Please enter a password");
    }

    if (!conPassword) {
      setErrorMsgConPassword("Please enter confirm password");
    }

    if (name && email && password && conPassword && password === conPassword) {
      fetchData();
    }
    // setErrorMsg("Invalid details");
  };

  return (
    <>
      <div className="loginPageContainer ">
        <Container maxWidth="md">
          <div className="regContainer textCenter">
            <Grid container>
              <Grid item xs={12}>
                <img
                  className="logoImg"
                  src={Images.logo.logoImage}
                  alt={Images.logo.alt}
                />
                <h2>Register user</h2>
                <div className="">
                  <TextField
                    id="name"
                    label="Name"
                    required
                    type="text"
                    value={name}
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 3 }}
                    onChange={handleActions}
                    error={!!errorMsgName}
                    helperText={errorMsgName}
                    inputProps={{ maxLength: 100 }}
                  />
                  <TextField
                    id="email"
                    label="Email"
                    required
                    type="text"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 3 }}
                    onChange={handleActions}
                    error={!!errorMsgEmail}
                    helperText={errorMsgEmail}
                    value={email}
                  />
                  <TextField
                    id="phone"
                    label="Phone"
                    required
                    type="text"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 3 }}
                    onChange={handleActions}
                    error={!!errorMsgPhone}
                    helperText={errorMsgPhone}
                    value={phone}
                  />
                  <TextField
                    id="address"
                    label="Address"
                    required
                    type="text"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 3 }}
                    onChange={handleActions}
                    error={!!errorMsgAddress}
                    helperText={errorMsgAddress}
                    value={address}
                  />
                  <TextField
                    id="password"
                    label="Password"
                    required
                    value={password}
                    type="password"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    sx={{ marginBottom: 3 }}
                    onChange={handleActions}
                    error={!!errorMsgPassword}
                    helperText={errorMsgPassword}
                  />

                  <TextField
                    id="conPassword"
                    label="Confirm password"
                    required
                    value={conPassword}
                    type="password"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    sx={{ marginBottom: 3 }}
                    onChange={handleActions}
                    error={!!errorMsgConPassword}
                    helperText={errorMsgConPassword}
                  />

                  <div className="btnCenter">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mr: 2 }}
                      onClick={() => onRegistrationButton()}
                    >
                      Submit
                    </Button>

                    <Button
                      type="reset"
                      variant="contained"
                      color="secondary"
                      onClick={onResetBtn}
                    >
                      Reset
                    </Button>
                  </div>

                  {setErrorMsg && <p className="error errorBtn">{errorMsg}</p>}
                  {/* {setSuccessMsg && <p className="successMsg"></p>} */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <AlertDialog
        title="Success"
        content={successMsg}
        action="toSubmit"
        actionName="Ok"
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
      />
    </>
  );
};
export default Registration;
