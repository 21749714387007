// #### Method 1
const Images = {
  logo: { alt: "chit-logo", logoImage: require("../assets/images/logo.jpeg") },
  logoNavbar: {
    alt: "chit-logo",
    logoImage: require("../assets/images/logo1.png"),
  },
  chitFund: {
    alt: "chit-fund",
    img: require("../assets/images/chit-fund.jpg"),
  },
  banner: {
    alt: "chit-fund-banner",
    img: require("../assets/images/banner.jpeg"),
  },
  about: {
    alt: "chit-fund-about",
    img: require("../assets/images/about-img.png"),
  },

  bannerImg: 
    {
      url: require("../assets/images/slider-img.png"),
      alt: "banner img 1",
      title: "Chit Funds",
      desc: "Financial arrangement or savings scheme commonly found in India, although similar systems exist in other parts of the world under different names. It operates as a rotating savings and credit association, providing a means for individuals to pool their resources and save money collectively. ",
    },
   

  // bannerImg: [
  //   {
  //     url: require("../assets/images/slider-img.png"),
  //     alt: "banner img 1",
  //     title: "Chit Funds",
  //     desc: "Financial arrangement or savings scheme commonly found in India, although similar systems exist in other parts of the world under different names. It operates as a rotating savings and credit association, providing a means for individuals to pool their resources and save money collectively. ",
  //   },
  //   {
  //     url: require("../assets/images/slider-img.png"),
  //     alt: "banner img 1",
  //     title: "Micro Financial Instituations",
  //     desc: "Microfinance has played a significant role in improving the lives of many low-income individuals by providing them with the tools and resources to become financially independent. However, it's essential to address challenges and continually refine approaches to maximize positive outcomes for clients and communities. ",
  //   },
  // ],

  servicesImg: [
    {
      url: require("../assets/images/s1.png"),
      alt: "Service 1",
      title: "Currency wallet",
      desc: " Fact is electronic wallet that allows individuals to store, send, and receive various currencies.",
    },
    {
      url: require("../assets/images/s2.png"),
      alt: "Service 2",
      title: "Security storage",
      desc: " This account should have robust security features and be regularly monitored for any suspicious activity. ",
    },
    {
      url: require("../assets/images/s3.png"),
      alt: "Service 2",
      title: "Expert security",
      desc: " Collaborating with professionals in these domains can help create a secure and well-managed for all participants. ",
    },
  ],

  chooseUsImg: [
    {
      url: require("../assets/images/w1.png"),
      alt: "Expert Management ",
      title: "Expert Management ",
      desc: " Incidunt odit rerum tenetur alias architecto asperiores omnis cumque doloribus aperiam numquam! Eligendi corrupti, molestias laborum dolores quod nisi vitae voluptate ipsa? In tempore voluptate ducimus officia id, aspernatur nihil. Tempore laborum nesciunt ut veniam, nemo officia ullam repudiandae repellat veritatis unde reiciendis possimus animi autem natus ",
    },
    {
      url: require("../assets/images/w2.png"),
      alt: "Secure Investment ",
      title: "Secure Investment ",
      desc: " Incidunt odit rerum tenetur alias architecto asperiores omnis cumque doloribus aperiam numquam! Eligendi corrupti, molestias laborum dolores quod nisi vitae voluptate ipsa? In tempore voluptate ducimus officia id, aspernatur nihil. Tempore laborum nesciunt ut veniam, nemo officia ullam repudiandae repellat veritatis unde reiciendis possimus animi autem natus ",
    },
    {
      url: require("../assets/images/w3.png"),
      alt: "Instant Trading ",
      title: "nstant Trading ",
      desc: " Incidunt odit rerum tenetur alias architecto asperiores omnis cumque doloribus aperiam numquam! Eligendi corrupti, molestias laborum dolores quod nisi vitae voluptate ipsa? In tempore voluptate ducimus officia id, aspernatur nihil. Tempore laborum nesciunt ut veniam, nemo officia ullam repudiandae repellat veritatis unde reiciendis possimus animi autem natus ",
    },
    {
      url: require("../assets/images/w4.png"),
      alt: "Happy Customers ",
      title: "Happy Customers ",
      desc: " Incidunt odit rerum tenetur alias architecto asperiores omnis cumque doloribus aperiam numquam! Eligendi corrupti, molestias laborum dolores quod nisi vitae voluptate ipsa? In tempore voluptate ducimus officia id, aspernatur nihil. Tempore laborum nesciunt ut veniam, nemo officia ullam repudiandae repellat veritatis unde reiciendis possimus animi autem natus ",
    },
  ],
};

// const entries = Object.entries(Images.logo);
// console.log(entries);

export default Images;

// Method 2 in object
// const imageData = {
//   section1: [
//     {
//       url: require('./path/to/section1/image1.jpg'),
//       alt: 'Alt Text for Section 1 - Image 1',
//     },
//     {
//       url: require('./path/to/section1/image2.jpg'),
//       alt: 'Alt Text for Section 1 - Image 2',
//     },

//   ],
//   section2: [
//     {
//       url: require('./path/to/section2/image1.jpg'),
//       alt: 'Alt Text for Section 2 - Image 1',
//     },
//     {
//       url: require('./path/to/section2/image2.jpg'),
//       alt: 'Alt Text for Section 2 - Image 2',
//     },
//     // ... add other images for section2
//   ],
//   // ... add other sections
// };

// Method 2 in array
// const imageData = [
//   {
//     section: 'section1',
//     images: [
//       {
//         url: require('./path/to/section1/image1.jpg'),
//         alt: 'Alt Text for Section 1 - Image 1',
//       },
//       {
//         url: require('./path/to/section1/image2.jpg'),
//         alt: 'Alt Text for Section 1 - Image 2',
//       },
//       // ... add other images for section1
//     ],
//   },
//   {
//     section: 'section2',
//     images: [
//       {
//         url: require('./path/to/section2/image1.jpg'),
//         alt: 'Alt Text for Section 2 - Image 1',
//       },
//       {
//         url: require('./path/to/section2/image2.jpg'),
//         alt: 'Alt Text for Section 2 - Image 2',
//       },
//       // ... add other images for section2
//     ],
//   },
//   // ... add other sections
// ];

// #### Method 2

// export default {
//   logo: {
//     alt: "noc-logo",
//     logoImage: require("../assets/images/logo.png"),
//   },
// };
