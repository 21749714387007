import React from "react";
import {
  Container,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";

const LandingPage = () => {
  return (
    <div>
      {/* Navbar */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Your Logo</Typography>
          {/* Add navigation links as per your requirements */}
        </Toolbar>
      </AppBar>

      {/* Slider Section */}
      <div id="slider" style={{ width: "100%", overflow: "hidden" }}>
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="d-block w-100"
                src="/path-to-your-image-1.jpg"
                alt="First slide"
              />
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src="/path-to-your-image-2.jpg"
                alt="Second slide"
              />
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src="/path-to-your-image-3.jpg"
                alt="Third slide"
              />
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </a>
        </div>
      </div>

      {/* About Section */}
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget
          scelerisque purus, ac bibendum metus. Nullam in bibendum enim. Nam
          euismod eros nec neque vehicula, ac scelerisque erat fermentum.
        </Typography>
        <Typography variant="body1" paragraph>
          Curabitur tincidunt sit amet quam vel pulvinar. Donec at auctor quam.
          Sed hendrerit odio a odio varius sollicitudin. Vivamus malesuada ex id
          fringilla venenatis. Quisque ut nisl est. Sed hendrerit odio a odio
          varius sollicitudin.
        </Typography>
      </Container>

      {/* Services Section */}
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Typography variant="h4" gutterBottom>
          Our Services
        </Typography>
        <Grid container spacing={3}>
          {/* Service Card 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Service 1
                </Typography>
                <Typography variant="body1">
                  Description of service 1. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Service Card 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Service 2
                </Typography>
                <Typography variant="body1">
                  Description of service 2. Sed eget scelerisque purus, ac
                  bibendum metus.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Service Card 3 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Service 3
                </Typography>
                <Typography variant="body1">
                  Description of service 3. Nullam in bibendum enim. Nam euismod
                  eros nec neque vehicula.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Footer Section */}
      <footer style={{ marginTop: "20px" }}>
        <Container maxWidth="md">
          <Typography variant="body2" align="center">
            &copy; {new Date().getFullYear()} Your Company. All Rights Reserved.
          </Typography>
        </Container>
      </footer>
    </div>
  );
};

export default LandingPage;
