import { Routes, Route } from "react-router-dom";

import Home from "./Home";
import Registration from "../auth/Registration";
import Login from "../auth/Login";
import Admin from "./AdminPage";
import MemberPassbookReport from "./MemberPassbookReport";
import SchemeForm from "./SchemeForm";
import PaymentReport from "./PaymentReport";
import ChitMain from "./ChitMain";
import Profile from "./Profile";
import MemberPassbookReportUser from "./MemberPassbookReportUser";
import LandingPage from "./LandingPage";
import PaymentReportUser from "./PaymentReportUser";

function Routers() {
  return (
    <div className="appContainer">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/member-report" element={<MemberPassbookReport />} />
        <Route
          path="/member-report-user"
          element={<MemberPassbookReportUser />}
        />
        <Route path="/chit" element={<ChitMain />} />
        <Route path="/member-group" element={<SchemeForm />} />
        <Route path="/payment-report" element={<PaymentReport />} />
        <Route path="/payment-report-user" element={<PaymentReportUser />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </div>
  );
}

export default Routers;
