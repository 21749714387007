import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import NavBarAdmin from "../components/NavBarAdmin";
import Sidebar from "../components/Sidebar";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect } from "react";
import AlertDialog from "../utilities/AlertDialog";
import ReactToPrint from "react-to-print";
import PaymentPrint from "../components/PaymentPrint";
import { useRef } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(231, 231, 242)",
    color: "rgb(47, 55, 70)",
    fontSize: 15,
    padding: "15px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "15px 10px",
    color: "rgb(17, 25, 39)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PaymentReportUser = () => {
  const [schemeData, setSchemeData] = useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [selectedScheme, setSelectedScheme] = useState([]);
  const [selectedMode, setSelectedMode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgGroup, setErrorMsgGroup] = useState("");
  const [errorMsgMode, setErrorMsgMode] = useState("");
  const [amount, setAmount] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const componentRef = useRef();
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const readOnlyStyle = {
    backgroundColor: "#f5f5f5", // Specify your desired background color
  };

  const user_id = localStorage.getItem("luserID");

  const fetchSchemeData = async () => {
    try {
      const response = await fetch(
        `https://localhost:44340/api/Admin/Getgrpsbyuser?id=${user_id}`
      );

      if (response.status === 200) {
        const schemes = await response.json();
        setSchemeData(schemes);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleSchemeActions = (event: SelectChangeEvent) => {
    const selectedSchemeId = event.target.value;
    setSelectedScheme(selectedSchemeId);
    setErrorMsgGroup("");
    setAmount(selectedSchemeId.split("#")[1]);

    // Fetch data based on the selected scheme
    fetchPaymentData(selectedSchemeId.split("#")[0]);
  };

  const handleModeActions = (event: SelectChangeEvent) => {
    setSelectedMode(event.target.value);
    setErrorMsgMode("");
    console.log(event.target.value);
    // onPaymentReport(event.target.value);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const currentDate = format(new Date(), "dd-MMM-yyyy");

  const savePaymentData = async () => {
    try {
      const response = await fetch(
        "https://localhost:44340/api/Admin/Save_payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            group_id: selectedScheme.split("#")[0],
            amount: amount,
            user_id: user_id,
            generated_by: "Admin",
            payment_mode: selectedMode,
          }),
        }
      );

      if (response.status === 200) {
        const token = await response.json();
        console.log(token);
        handleModalOpen(true);
        fetchPaymentData(selectedScheme.split("#")[0]);
        const message = (
          <div className="messageDiv">
            <p className="successMsg">Payment successfully</p>
            <div className="printContainer">
              <div className="printDiv">
                <h6>Scheme name </h6>
                <h6>:</h6>
                <h6>{selectedScheme.split("#")[0]}</h6>
              </div>
              <div className="printDiv">
                <h6>Payment</h6>
                <h6>:</h6>
                <h6>{amount}</h6>
              </div>
              <div className="printDiv">
                <h6>Payment ID</h6>
                <h6>:</h6>
                <h6>{token}</h6>
              </div>
              <div className="printDiv">
                <h6>Payment mode</h6>
                <h6>:</h6>
                <h6>{selectedMode}</h6>
              </div>
              <div className="printDiv">
                <h6>Date</h6>
                <h6>:</h6>
                <h6>{currentDate}</h6>
              </div>
            </div>
          </div>
        );
        setSuccessMsg(message);
        setSelectedMode("");
      } else {
        setErrorMsg("Your payment is already done");
      }

      // setuserid(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const onHandlePaymentbtn = () => {
    setErrorMsgGroup(selectedScheme ? null : "Please select group");
    setErrorMsgMode(selectedMode ? null : "Please select payment mode");

    console.log(selectedScheme, selectedMode, amount);

    if (selectedScheme && selectedMode) {
      savePaymentData();
    }
    setErrorMsg("");
  };

  const fetchPaymentData = async (groupId) => {
    const url = groupId
      ? `https://localhost:44340/api/Admin/Getpymntbyscheme?grp_id=${groupId}&user_id=${user_id}`
      : `https://localhost:44340/api/Admin/Getpymntbyscheme?user_id=${user_id}`;

    try {
      const response = await fetch(url);
      console.log(response);
      if (response.status === 200) {
        const responseData = await response.json();
        setPaymentHistory(responseData);
        console.log(responseData);
      } else {
        console.error("Error fetching data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSchemeData();
    // fetchPaymentData();
  }, []);

  return (
    <>
      <div
        className={`grid-container ${
          openSidebarToggle ? "grid-menu-hide" : ""
        }`}
      >
        <NavBarAdmin OpenSidebar={OpenSidebar} />
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <main className="main-container dashboardLarge">
          <div className="main-title ">
            <h3>Payment report</h3>
          </div>
          <div className="boxContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="group">Select group</InputLabel>
                  <Select
                    fullWidth
                    labelId="schemeData"
                    id="schemeData"
                    label="Select group"
                    value={selectedScheme}
                    onChange={handleSchemeActions}
                  >
                    {schemeData.map((scheme) => (
                      <MenuItem
                        key={scheme.group_id}
                        value={scheme.group_id + "#" + scheme.mem_amt}
                      >
                        {scheme.group_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {errorMsgGroup && <p className="error">{errorMsgGroup}</p>}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <FormControl fullWidth>
                  <InputLabel id="payMode">Payment mode</InputLabel>
                  <Select
                    labelId="payMode"
                    id="demo-simple-select"
                    value={selectedMode}
                    label="Payment mode"
                    onChange={handleModeActions}
                  >
                    <MenuItem value={"cash"}>Cash</MenuItem>
                    <MenuItem value={"upi"}>UPI</MenuItem>
                  </Select>
                </FormControl>
                {errorMsgMode && <p className="error">{errorMsgMode}</p>}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                <TextField
                  id="netAMount"
                  label="Amount"
                  variant="outlined"
                  value={amount}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  style={readOnlyStyle}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6} lg={2} xl={2}>
                <Button
                  variant="contained"
                  size="large"
                  className="reportBtn"
                  onClick={onHandlePaymentbtn}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
          {setErrorMsg && <p className="error errorBtn">{errorMsg}</p>}

          <div className="tableContainer">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Payment ID</StyledTableCell>
                    <StyledTableCell>Group value</StyledTableCell>
                    <StyledTableCell>Inst #</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Mode</StyledTableCell>
                    <StyledTableCell>Net payable</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentHistory.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={7} align="center">
                        No data available
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    <>
                      {paymentHistory.map((report) => (
                        <StyledTableRow key={report.payment_id}>
                          <StyledTableCell>{report.payment_id}</StyledTableCell>
                          <StyledTableCell>{report.grp_amt}</StyledTableCell>
                          <StyledTableCell>
                            {report.installment}
                          </StyledTableCell>
                          <StyledTableCell>
                            {report.generated_date}
                          </StyledTableCell>
                          <StyledTableCell>
                            {report.payment_mode}
                          </StyledTableCell>

                          <StyledTableCell>{report.amount}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* <Grid item xs={12} className="btnCenter mt-20">
            <ReactToPrint
              trigger={() => <Button variant="contained">Print</Button>}
              content={() => componentRef.current}
            />
          </Grid> */}
        </main>
      </div>
      <AlertDialog
        title="Success"
        content={successMsg}
        action="toSubmit"
        actionName="Ok"
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default PaymentReportUser;
