import React, { useState } from "react";
import {
  BsGridFill,
  BsFillFileEarmarkPersonFill,
  BsFillFileEarmarkTextFill,
  BsPeopleFill,
  BsFillPersonFill,
} from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import Images from "../utilities/Images";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const user_type = localStorage.getItem("luserType");
  return (
    <aside
      id="sidebar"
      className={openSidebarToggle ? "sidebar-responsive" : ""}
    >
      <div className="sidebar-title">
        { <div className="sidebar-brand">
          <img
            className="chitAdminLogo"
            src={Images.logoNavbar.logoImage}
            alt={Images.logoNavbar.alt}
          />
          <h5>Chit Funds</h5>
        </div> }
        <span className="icon close_icon" onClick={OpenSidebar}>
          <CloseIcon />
        </span>
      </div>
      <ul className="sidebar-list">
        {user_type === "A" && (
          <NavLink to="/admin" activeclassname="active">
            <li className="sidebar-list-item">
              <BsGridFill className="icon" /> Dashboard
            </li>
          </NavLink>
        )}
        {user_type === "A" && (
          <NavLink to="/member-group" activeclassname="active">
            <li className="sidebar-list-item">
              <BsPeopleFill className="icon" /> Chit group
            </li>
          </NavLink>
        )}
        {user_type === "A" && (
          <NavLink to="/chit" activeclassname="active">
            <li className="sidebar-list-item">
              <BsFillFileEarmarkTextFill className="icon" /> Chit
            </li>
          </NavLink>
        )}
        {user_type === "A" && (
          <NavLink to="/profile" activeclassname="active">
            <li className="sidebar-list-item">
              <BsFillPersonFill className="icon" /> New Agent
            </li>
          </NavLink>
        )}

        {user_type === "A" ? (
          <NavLink to="/member-report" activeclassname="active">
            <li className="sidebar-list-item">
              <BsFillFileEarmarkPersonFill className="icon" /> Member passbook
            </li>
          </NavLink>
        ) : (
          <NavLink to="/member-report-user" activeclassname="active">
            <li className="sidebar-list-item">
              <BsFillFileEarmarkPersonFill className="icon" /> User Member
              passbook
            </li>
          </NavLink>
        )}

        {user_type === "A" ? (
          <NavLink to="/payment-report" activeclassname="active">
            <li className="sidebar-list-item">
              <PaymentIcon className="icon" /> Payment report
            </li>
          </NavLink>
        ) : (
          <NavLink to="/payment-report-user" activeclassname="active">
            <li className="sidebar-list-item">
              <PaymentIcon className="icon" />
              Payment Report
            </li>
          </NavLink>
        )}
      </ul>
    </aside>
  );
}

export default Sidebar;
