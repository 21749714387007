import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, Grid, InputLabel, MenuItem } from "@mui/material";
import {
  BsPeopleFill,
  BsFillPersonLinesFill,
  BsCurrencyRupee,
} from "react-icons/bs";

function Dashboard() {
  const [schemeData, setSchemeData] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState("");
  const [mem_cnt, setmem_cnt] = useState("");
  const [tot_cnt, settot_cnt] = useState("");
  const [admintot_cnt, setadmintot_cnt] = useState("");
  const [paid_cnt, setpaid_cnt] = useState("");

  const fetchDashboardData = async () => {
    try {
      const response = await fetch(
        "https://localhost:44340/api/Admin/Getdashboard?",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log("Dashboard Data:", data);
        //setData(data[0]);
        setmem_cnt(data[0].mem_cnt);
        settot_cnt(data[0].tot_amt);
        setadmintot_cnt(
          parseFloat(parseFloat(data[0].tot_amt * 5) / 100).toFixed(2)
        );
        setpaid_cnt(data[0].paid_amt);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const fetchSchemeData = async () => {
    try {
      const response = await fetch(
        "https://localhost:44340/api/Admin/Getschemes"
      );

      if (response.status === 200) {
        const schemes = await response.json();
        setSchemeData(schemes);
        console.log("Scheme Data:", schemes);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    fetchSchemeData();
  }, []);

  const handleSchemeActions = async (e) => {
    const id = e.target.value;
    setSelectedScheme(id);
    const url = id
      ? `https://localhost:44340/api/Admin/Getdashboard?id=${id}`
      : "https://localhost:44340/api/Admin/Getdashboard";

    try {
      const response = await fetch(url);

      if (response.status === 200) {
        const data = await response.json();
        console.log("Dashboard Data:", data);
        //setData(data[0]);
        setmem_cnt(data[0].mem_cnt);
        settot_cnt(data[0].tot_amt);
        setadmintot_cnt(
          parseFloat(parseFloat(data[0].tot_amt * 5) / 100).toFixed(2)
        );
        setpaid_cnt(data[0].paid_amt);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <main className="main-container dashboardLarge">
      <div className="spaceBetween">
        <div className="main-title">
          <h3>DASHBOARD</h3>
        </div>
        <FormControl className="selectWidth">
          <InputLabel id="schemeData">Select group</InputLabel>
          <Select
            fullWidth
            labelId="schemeData"
            id="schemeData"
            label="Select group"
            value={selectedScheme}
            onChange={handleSchemeActions}
          >
            {schemeData.map((scheme) => (
              <MenuItem key={scheme.group_id} value={scheme.group_id}>
                {scheme.group_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="main-cards mb-20">
        <>
          <div className="card">
            <div className="card-inner">
              <div className="cardIconBox">
                <BsFillPersonLinesFill className="card_icon" />
              </div>
              <h3>No of members</h3>
            </div>
            <h1>{mem_cnt}</h1>
          </div>
          <div className="card">
            <div className="card-inner">
              <div className="cardIconBox">
                <BsPeopleFill className="card_icon" />
              </div>
              <h3>Admin profit</h3>
            </div>
            <h1>{admintot_cnt}</h1>
          </div>
          <div className="card">
            <div className="card-inner">
              <div className="cardIconBox">
                <BsCurrencyRupee className="card_icon" />
              </div>
              <h3>Total amount</h3>
            </div>
            <h1>{tot_cnt}</h1>
          </div>
          <div className="card">
            <div className="card-inner">
              <div className="cardIconBox">
                <BsCurrencyRupee className="card_icon" />
              </div>
              <h3>Paid amount</h3>
            </div>
            <h1>{paid_cnt}</h1>
          </div>
        </>
      </div>
    </main>
  );
}

export default Dashboard;
