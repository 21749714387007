// SchemeForm.js
import * as React from "react";
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  TableCell,
  tableCellClasses,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import NavBarAdmin from "../components/NavBarAdmin";
import Sidebar from "../components/Sidebar";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AlertDialog from "../utilities/AlertDialog";
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(231, 231, 242)",
    color: "rgb(47, 55, 70)",
    fontSize: 15,
    padding: "15px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "15px 10px",
    color: "rgb(17, 25, 39)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SchemeForm = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [members, setMembers] = useState("");
  const [amount, setAmount] = useState("");
  const [months, setMonths] = useState("");
  const [date, setDate] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgGroup, setErrorMsgGroup] = useState("");
  const [errorMsgMem, setErrorMsgMem] = useState("");
  const [errorMsgAmount, setErrorMsgAmount] = useState("");
  const [errorMsgMonths, setErrorMsgMonths] = useState("");
  const [errorMsgDate, setErrorMsgDate] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [dataHistory, setHistoryData] = useState([]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  const fetchHistory = async () => {
    try {
      const response = await fetch(
        "https://localhost:44340/api/Admin/Getschemes"
      );

      console.log(response);
      if (response.status === 200) {
        const token = await response.json();
        const dataWithSno = token.map((item, index) => ({
          ...item,
          sno: index + 1,
        }));
        setHistoryData(dataWithSno);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const fetchData = async () => {
    const user_id = localStorage.getItem("luserID");

    try {
      const response = await fetch(
        "https://localhost:44340/api/Admin/Save_group",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            group_name: groupName,
            no_of_mem: members,
            amount: amount,
            months: months.toString(),
            start_date: date,
            generated_by: user_id,
          }),
        }
      );

      console.log(response);
      if (response.status === 200) {
        const data = await response.json();
        setData(data);
        console.log(data);
        fetchHistory();

        handleModalOpen();
        const message = (
          <div className="messageDiv">
            <p>Member group added successfully</p>
          </div>
        );
        setSuccessMsg(message);

        onResetBtn();
      } else {
        setErrorMsg("Something went wrong");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleActions = (e) => {
    const { id, value } = e.target;

    if (id === "groupName") {
      setGroupName(value);
      setErrorMsgGroup("");
    } else if (id === "members") {
      setMembers(value);
      setErrorMsgMem("");
    } else if (id === "amount") {
      setAmount(value);
      setErrorMsgAmount("");
    }
    setErrorMsg("");
  };

  const handleMonthActions = (event: SelectChangeEvent) => {
    setMonths(event.target.value);
    setErrorMsgMonths("");
  };

  const handleDateChange = (newDate) => {
    const date = new Date(newDate);
    const formattedDate = format(date, "dd-MMM-yyyy");
    setDate(formattedDate);
    // setErrorMsgDate("");
    console.log(formattedDate);
    setErrorMsgDate("");
  };

  const onResetBtn = () => {
    setGroupName("");
    setMembers("");
    setAmount("");
    setMonths("");
    setDate(null);
    setErrorMsgGroup("");
    setErrorMsgMem("");
    setErrorMsgAmount("");
    setErrorMsgMonths("");
    setErrorMsgDate("");
  };

  const onSaveButton = () => {
    setErrorMsgGroup(groupName ? "" : "Enter group");
    setErrorMsgMem(members ? "" : "Enter members");
    setErrorMsgAmount(amount ? "" : "Enter amount");
    setErrorMsgMonths(months ? "" : "Select months");
    setErrorMsgDate(date ? "" : "Select date");
    if (groupName && members && amount && months && date) {
      fetchData();
    }
    //
  };

  const menuItems = Array.from({ length: 24 }, (_, index) => (
    <MenuItem key={index + 1} value={index + 1}>
      {index + 1}
    </MenuItem>
  ));

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <>
      <div
        className={`grid-container ${
          openSidebarToggle ? "grid-menu-hide" : ""
        }`}
      >
        <NavBarAdmin OpenSidebar={OpenSidebar} />
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <main className="main-container dashboardLarge">
          <div className="main-title ">
            <h3>Chit Group</h3>
          </div>
          <div>
            <div className="boxContainer">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                  <TextField
                    id="groupName"
                    type="text"
                    label="Group name"
                    required
                    variant="outlined"
                    fullWidth
                    value={groupName}
                    onChange={handleActions}
                  />
                  {errorMsgGroup && <p className="error">{errorMsgGroup}</p>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <TextField
                    id="members"
                    type="number"
                    label="Total members"
                    required
                    variant="outlined"
                    fullWidth
                    value={members}
                    onChange={handleActions}
                  />
                  {errorMsgMem && <p className="error">{errorMsgMem}</p>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <TextField
                    id="amount"
                    type="number"
                    label="Amount"
                    required
                    variant="outlined"
                    fullWidth
                    value={amount}
                    onChange={handleActions}
                  />
                  {errorMsgAmount && <p className="error">{errorMsgAmount}</p>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <FormControl fullWidth>
                    <InputLabel id="group">Months *</InputLabel>
                    <Select
                      labelId="months"
                      id="months"
                      required
                      fullWidth
                      value={months}
                      label="Months *"
                      onChange={handleMonthActions}
                    >
                      {menuItems}
                    </Select>
                    {errorMsgMonths && (
                      <p className="error">{errorMsgMonths}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} required>
                    <DatePicker
                      className="datePickerWidth"
                      id="date"
                      required
                      label="Start date *"
                      defaultValue=""
                      onChange={handleDateChange}
                      error={!!errorMsgDate}
                      helperText={errorMsgDate}
                      value={date}
                      format="DD-MMM-YYYY"
                    />
                  </LocalizationProvider>
                  {errorMsgDate && <p className="error">{errorMsgDate}</p>}
                </Grid>

                <Grid item xs={12} className="btnCenter mt-10">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mr: 2 }}
                    onClick={onSaveButton}
                  >
                    Submit
                  </Button>

                  <Button
                    type="reset"
                    variant="contained"
                    color="secondary"
                    onClick={onResetBtn}
                  >
                    Reset
                  </Button>
                </Grid>
                {errorMsg ? <p className="error errorBtn">{errorMsg}</p> : null}
              </Grid>
            </div>
          </div>
          <div className="tableContainer">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>#</StyledTableCell>
                    <StyledTableCell>Group name</StyledTableCell>
                    <StyledTableCell>Total members</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                    <StyledTableCell>Months</StyledTableCell>
                    <StyledTableCell>Start date</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataHistory.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={6} align="center">
                        No data available
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    <>
                      {dataHistory.map((row) => (
                        <StyledTableRow key={row.sno}>
                          <StyledTableCell component="th" scope="row">
                            {row.sno}
                          </StyledTableCell>
                          <StyledTableCell>{row.group_name}</StyledTableCell>
                          <StyledTableCell>{row.no_of_mem}</StyledTableCell>
                          <StyledTableCell>{row.amount}</StyledTableCell>
                          <StyledTableCell>{row.months}</StyledTableCell>
                          <StyledTableCell>{row.start_date}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </main>
      </div>
      <AlertDialog
        title="Success"
        content={successMsg}
        action="toSubmit"
        actionName="Ok"
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default SchemeForm;
