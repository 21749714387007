import { TextField, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../utilities/Images";
import { handleKeyPressNumbers } from "../utilities/keypressUtils";
import jwt_decode from "jwt-decode";

const Login = () => {
  const [userid, setuserid] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsgUserid, setErrorMsgUserid] = useState("");
  const [errorMsgPwd, setErrorMsgPwd] = useState("");

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch("https://localhost:44340/api/signon/Index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userid,
          password: password,
        }),
      });
      if (response.status === 200) {
        const token = await response.json();
        var decoded = jwt_decode(token);
        console.log(decoded);
        localStorage.setItem("luserID", decoded.user_id);
        localStorage.setItem("luserName", decoded.user_name);
        localStorage.setItem("luserType", decoded.user_type);
        if (decoded.user_type === "A") {
          navigate("/admin", { state: { userid: decoded.user_id } });
        } else {
          navigate("/member-report-user", {
            state: { userid: decoded.user_id },
          });
        }
      } else {
        setErrorMsg("Invalid credentials");
      }

      // setuserid(data);
    } catch (error) {
      setErrorMsg("Something went wrong");
      console.error("Fetch error:", error);
    }
  };

  const handleInput = (e) => {
    const { id, value } = e.target;

    if (id === "userid") {
      setuserid(value);
      setErrorMsgUserid("");
    } else if (id === "password") {
      setPassword(value);
      setErrorMsgPwd("");
    }
    setErrorMsg("");
  };

  const onLoginButton = () => {
    setErrorMsgUserid(userid ? null : "Please enter User ID");
    setErrorMsgPwd(password ? null : "Please enter Password");

    if (userid && password) {
      fetchData();
    } else {
      setErrorMsg("");
    }
  };

  return (
    <>
      <div className="loginPageContainer">
        <div className="loginContainer">
          <Grid container>
            <Grid item xs={12}>
              <div className="loginForm">
                <img
                  className="logoImg"
                  src={Images.logo.logoImage}
                  alt={Images.logo.alt}
                />
                <div>
                  <TextField
                    id="userid"
                    type="text"
                    label="Agent ID"
                    required
                    variant="outlined"
                    fullWidth
                    value={userid}
                    onChange={handleInput}
                    sx={{ marginBottom: 3 }}
                    error={!!errorMsgUserid}
                    helperText={errorMsgUserid}
                    onKeyDown={handleKeyPressNumbers}
                    inputProps={{ maxLength: 10 }}
                  />

                  <TextField
                    id="password"
                    type="password"
                    label="Password"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={handleInput}
                    sx={{ marginBottom: 3 }}
                    error={!!errorMsgPwd}
                    helperText={errorMsgPwd}
                    // inputProps={{ maxLength: 10 }}
                  />
                  <p className="zeroMargin textRight mb30 smtext ">
                    Forgot password ?
                  </p>

                  <Button variant="contained" onClick={() => onLoginButton()}>
                    Login
                  </Button>
                </div>
                {setErrorMsg && <p className="error errorBtn">{errorMsg}</p>}
                {setSuccessMsg && <p className="successMsg">{successMsg}</p>}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Login;
